import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import Hero from '../components/sections/Hero/Hero';
import Welcome from '../components/sections/Welcome';

export default function Index({ data }:PageProps<IndexQueryData>) {
  React.useEffect(() => {
    const gTagEvent =  {
      'event': 'Pageview',
      'loginStatus': 'out',
      'pageArea': 'pub',
      'pageItem': ' free trial complete thank you',
      'pageSection': 'new info page',
      'experience': 'v2',
      'pageType': 'info page'
    };
    const tagData = {
      'iBlink': {
        'experience': 'v2'
      },
      'global': {
        'pageArea': 'pub',
        'pageSection': 'new info page',
        'pageItem': 'free trial complete thank you',
        'loginStatus': 'out'
      }
    };
    window.tagData = tagData;
    window.dataLayer.push(gTagEvent);
  }, []);

  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>Free Trial Thank You | {data.site.siteMetadata.name}</title>
    </Helmet>
    <Hero />
    <Welcome />
  </>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
